























































































































































































































import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import Loader from "@/components/General/Loader.vue";
import DetailHeader from "@/components/Layout/Backoffice/DetailHeader.vue";
import { Notification } from "@/models/notification.interface";
import { CustomDate } from "@/mixins/custom-date";
import InnerImageZoom from "vue-inner-image-zoom";

@Component({
  components: {
    Loader,
    DetailHeader,
    "inner-image-zoom": InnerImageZoom,
  },
})
export default class CheckUser extends mixins(Navigation, CustomDate) {
  loader = false;
  dialogPaymentDetail = false;
  paymentPreview = {};
  loadingA = false;
  loadingR = false;
  checkUserDialog = false;

  private async created(): Promise<void> {
    this.loader = true;

    await this.$store
      .dispatch("bill/getBill", this.$route.params.id)
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.bd-error-1") + this.$route.params.id,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });

    await this.$store
      .dispatch("kyc/getUserKYC", this.bill.client.id)
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.ukyc-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });

    this.setNavigation({
      previousRoute: "/staff/investments",
      icon: "mdi-account-lock-open",
      title: `${this.$tc("Views.sn-1")}: ${this.$route.params.id}`,
    });
    this.loader = false;
  }

  private get bill() {
    return this.$store.getters["bill/getBill"];
  }

  private get userKYC() {
    return this.$store.getters["kyc/getUserKYC"];
  }

  private async approveUserApplication() {
    this.loadingA = true;
    await this.$store
      .dispatch("bill/updateBillStatus", {
        id: this.$route.params.id,
        status: this.setNewStatus(),
      })
      .then(() => {
        this.checkUserDialog = true;
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.ukyc-e2"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
    this.loadingA = false;
  }

  private async rejectUserApplication() {
    this.loadingR = true;
    await this.$store
      .dispatch("bill/updateBillStatus", {
        id: this.$route.params.id,
        status: this.$constants.STATUS.CANCELED,
      })
      .then(() => {
        this.checkUserDialog = true;
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.ukyc-e3"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
    this.loadingR = false;
  }

  private setNewStatus() {
    if (
      this.bill.status.name == this.$constants.STATUS.RESERVED &&
      this.bill.products_bill[0].product.investment.is_a_bucket
    ) {
      return this.$constants.STATUS.WAITING_FOR_USERS;
    } else if (
      this.bill.status.name == this.$constants.STATUS.RESERVED &&
      !this.bill.products_bill[0].product.investment.is_a_bucket
    ) {
      return this.$constants.STATUS.TO_DO;
    }
  }

  private goToSalesPage() {
    this.navigate("/staff/investments");
  }
}
